import React from "react";
import Header from "../components/module-banner";
import Panel from "../components/pn-panel";
import RoutineContent from "../components/routine-content";
import Analytics from "../components/analytics";
import PNButton from "../components/pn-button";
import ThemeChanger from "../components/themeChanger";

const getResizedImg = (node, size) => {
    if (node.frontmatter === undefined) return '';
    let srcSet = node.frontmatter.featuredImage.childImageSharp.fluid.srcSet;
    let imgPath = srcSet.split(',').find(elem => elem.indexOf(size.toString()) !== -1).trim().split(' ')[0] || "";
    return imgPath || '';
}

const checkValidDate = (expDateString) => {
    if (!expDateString) return true;
    let expDate = new Date(`${expDateString.slice(0,4)}-${expDateString.slice(4,6)}-${expDateString.slice(6,8)}`);
    let datenow = new Date(Date.now());
    const diffTime = datenow - expDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 1;
}

const filterExpired = (routines) => {
    return routines.filter(routine => checkValidDate(routine.expiration));
}

export default ({ pageContext, data }) => {

    const assetHeader = data.assetHeader.nodes[0];
    const assetPanelHeader = data.assetPanelHeader.nodes[0];
    const headerImg = getResizedImg(assetHeader, 1024);
    const panelImg = getResizedImg(assetPanelHeader, 1024);

    let headerTitle;
    if (pageContext.language === 'por') headerTitle = "Seja bem-vindo à página do";
    if (pageContext.language === 'eng') headerTitle = "Welcome to the homepage of";
    if (pageContext.language === 'spa') headerTitle = "Bienvenido a la pagina de";

    let subtitle;
    if (pageContext.language === 'por') subtitle = "Aqui você tem acesso as novidades e demos das rotinas";
    if (pageContext.language === 'eng') subtitle = "Here you have access to program news and demos";
    if (pageContext.language === 'spa') subtitle = "Aquí tienes acceso a las novedades y demostraciones de las rutinas";

    let readMore;
    if (pageContext.language === 'por') readMore = "Saiba mais";
    if (pageContext.language === 'eng') readMore = "Read more";
    if (pageContext.language === 'spa') readMore = "Sepa mais";

    let tn_text;
    if (pageContext.language === 'por') tn_text = "Clique aqui para conferir as novidades do módulo";
    if (pageContext.language === 'eng') tn_text = "Click here to check the module news";
    if (pageContext.language === 'spa') tn_text = "Haga clic aquí para ver las noticias sobre el módulo";
    
    let tn_url = `https://totvsnews.engpro.totvs.com.br/${pageContext.module}/${pageContext.country}/${pageContext.language}/`;

    return (
        <div style={{textAlign: "center", alignItems: "center", display: "flex", "flex-direction": "column"}}>
            <Analytics />
            <ThemeChanger />
            <Header imgPath={headerImg} title={headerTitle} module={pageContext.module} subtitle={subtitle}/>  
            <PNButton url={tn_url} text={tn_text} imgPath={panelImg} />
            {console.log(pageContext.routines)}  
            {filterExpired(pageContext.routines).map(routine => {
                let imgContext = {
                    module: pageContext.module,
                    country: pageContext.country,
                    language: pageContext.language,
                    filename: routine.filename,
                    fileExtension: routine.fileExtension,
                    expiration: routine.expiration,
                    type: routine.type,
                    link: routine.link,
                    text: routine.text,
                    title: routine.title,
                    name: routine.name
                }

                let panelTitle = `${routine.name} - ${routine.title}`;

                return (
                <Panel title={panelTitle} imgPath={panelImg}>
                    <RoutineContent context={imgContext} />
                    {imgContext.link && <a target="_placeholder" rel="noopener noreferrer" href={imgContext.link}>
                        <u>{readMore}</u>
                    </a>}
                </Panel>)
            })}
        </div>
    )
}

export const query = graphql`
query {
    assetHeader: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "module-banner"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                    fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
            }
        }
    }

    assetPanelHeader: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "pn-panel"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        }
    }
}
`